import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import Images from '@components/Images';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData } from '@utils/extractors';
import { ENUM_STATIC_MENU_LINKS } from '@utils/types';

type TUrl = {
  url: string;
};

type TBrand = {
  logo: TUrl;
  image: TUrl;
  description: string;
  link: TUrl;
  link_text: string;
};

interface IOurBrandsProps {
  data: {
    prismic: {
      allOur_brands_pages: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const OurBrands: React.FC<IOurBrandsProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allOur_brands_pages');
  if (!doc) return null;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  return (
    <Layout
      title={page_title ? RichText.asText(page_title) : 'Our Brands'}
      description={page_description && RichText.asText(page_description)}
      firstLevelPage
      seo={{
        path: ENUM_STATIC_MENU_LINKS['OUR BRANDS'],
        canonical: canonical_tag && canonical_tag.url,
        image: page_og_image ? page_og_image : null,
      }}
    >
      <div className="our-brands">
        <div className="our-brands__help">
          <div className="our-brands__content">
            <div className="our-brands__bck">
              <div className="our-brands__inner anim-title">
                <h1>{RichText.asText(doc.node.heading)}</h1>
                {RichText.render(doc.node.intro_text, linkResolver)}
              </div>

              {doc.node.brands.map((brand: TBrand, index: number) => {
                return (
                  <Fragment key={index}>
                    <div className="our-brands__brand anim-brands">
                      <div className="our-brands__text-help">
                        <div className="our-brands__text anim-brands__text-block">
                          <Images
                            imageData={[{'image': brand.logo}]}
                            extractPath="image"
                            alt={_get(brand, ['logo', 'alt'])}
                            classes={{
                              base: '',
                              extend: '',
                              wrap: '',
                              class: 'our-brands__logo'
                            }}
                            ar="192:50"
                            width="526"
                            clear={true}
                          />
                          {RichText.render(brand.description, linkResolver)}
                          { brand.link &&
                            <a href={brand.link.url} className="our-brands__link">
                              <span className="our-brands__link-arrow"></span>
                              {RichText.asText(brand.link_text)}
                            </a>
                          }
                        </div>
                      </div>
                      <div className="our-brands__image">
                        <Images
                          imageData={[{'image': brand.image}]}
                          extractPath="image"
                          alt={_get(brand, ['image', 'alt'])}
                          classes={{
                            base: 'anim-brands',
                            extend: '',
                            wrap: 'our-brands__img',
                            class: ''
                          }}
                          width="526"
                        />
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query OurBrands {
    prismic {
      allOur_brands_pages {
        edges {
          node {
            heading
            intro_text
            brands {
              description
              link_text
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              image
              logo
            }
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default OurBrands;
